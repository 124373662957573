import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function BottomTooltip({ children, message }) {
    return (
        <span>
            <OverlayTrigger
                placement="bottom"
                trigger="hover"
                overlay={<Tooltip className="fadeInFast">{message}</Tooltip>}
            >
                <span>{children}</span>
            </OverlayTrigger>
        </span>
    );
}
