import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function TopTooltip({ children, message }) {
    return (
        <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{message}</Tooltip>}>
            <span>{children}</span>
        </OverlayTrigger>
    );
}
