import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LanguageLogo from "../LanguageLogo";
import BottomTooltip from "../tooltips/BottomTooltip";

export default function IconsGrid({ numOfCols, lst }) {
    const iconsGrid = (numOfCols, lst) => {
        var rowData = [];
        var imgRowData = [];

        for (var i = 0; i < lst.length; i++) {
            if (i % numOfCols === 0) {
                imgRowData = [];
            }

            imgRowData.push(
                <Col className="GridImageContainer">
                    <BottomTooltip message={lst[i].caption}>
                        <LanguageLogo img={lst[i].source} alt={lst[i].alt} />
                    </BottomTooltip>
                </Col>
            );

            if (i % numOfCols === numOfCols - 1) {
                rowData.push(<Row>{imgRowData}</Row>);
            } else if (i === lst.length - 1) {
                var cellRemaining = numOfCols - (i % numOfCols) - 1;

                for (var j = 0; j < cellRemaining; j++) {
                    imgRowData.push(<Col></Col>);
                }

                rowData.push(<Row>{imgRowData}</Row>);
            }
        }

        var allGridData = [];
        allGridData.push(<Container fluid>{rowData}</Container>);
        return allGridData;
    };

    const DEFAULT_NUMBER_OF_COLS = 4;
    return <div>{iconsGrid(numOfCols || DEFAULT_NUMBER_OF_COLS, lst)}</div>;
}
