import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import IconsGrid from "../../components/grid/IconsGrid";
import SectionWrapper from "../../components/SectionWrapper";
import { FRAMEWORK_ICONS } from "../../constants/Frameworks";
import { LANGUAGE_ICONS } from "../../constants/Languages";
import { SKILLS_ANCHOR } from "../../constants/RoutePath";

export default function Skills() {
    return (
        <SectionWrapper title="Skills" id={SKILLS_ANCHOR}>
            <Tabs defaultActiveKey="languages" variant="pills" class="TabButtonContainer">
                <Tab eventKey="languages" title="LANGUAGES">
                    <div style={{ animation: "fadeInAnimation ease 1s" }}>
                        <div className="TabDescription">
                            I learn new languages quickly and continuously seek to improve my proficiency in them.
                        </div>
                        <IconsGrid lst={LANGUAGE_ICONS} />
                    </div>
                </Tab>
                <Tab eventKey="frameworks" title="FRAMEWORKS & TOOLS">
                    <div style={{ animation: "fadeInAnimation ease 1s" }}>
                        <div className="TabDescription">
                            I enjoy building efficient and scalable applications with clean and simple-to-use UIs.
                        </div>
                        <IconsGrid lst={FRAMEWORK_ICONS} />
                    </div>
                </Tab>
            </Tabs>
        </SectionWrapper>
    );
}
