import { IEEE_HKN } from "./LogoPath";

export const CCA_DETAILS = [
    {
        title: "President @",
        image: IEEE_HKN,
        website: "https://nushkn.org/",
        tooltip: "Organisation Website",
        time: "JUN 2022 - PRESENT",
        subtitle: "IEEE-Eta Kappa Nu (IEEE-HKN) Lambda Omega (NUS) Chapter",
        description: {
            details: [
                "Awarded Key Chapter Recognition in 2022",
                "Responsible for organising 5 community outreach events and industrial visits, benefiting over 200 NUS undergraduate students",
                "Hosted 50 students and faculty members from American University of Sharjah of UAE for their visit to NUS."
            ],
        },
    },
    {
        title: "Project Director of External Relations @",
        image: IEEE_HKN,
        website: "https://nushkn.org/",
        tooltip: "Organisation Website",
        time: "AUG 2020 - JUN 2022",
        subtitle: "IEEE-Eta Kappa Nu (IEEE-HKN) Lambda Omega (NUS) Chapter",
        description: {
            details: [
                "Organized PhD Sharing Sessions and Industrial Talks for audiences from NUS and other institutions",
                "Reached out to the NUS PhD Students and Alumni to invite speakers and promote the events",
            ],
        },
    }
];
