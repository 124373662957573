import {
    ANDROID_STUDIO,
    ARDUINO,
    ARM_CORTEX,
    BOOTSTRAP,
    CLOUD_FIRESTORE,
    CLOUD_STORAGE,
    EXPO,
    FLUTTER,
    GAZEBO,
    GIT,
    GITHUB,
    GRADLE,
    NODEJS,
    OPENCV,
    PHOTOSHOP,
    PREMIERE_PRO,
    PYTORCH,
    RASPBERRY,
    REACT,
    REALTIME_DATABASE,
    REDUX,
    TENSORFLOW,
    TRAVIS,
    UNITY
} from "./LogoPath";

export const FRAMEWORK_ICONS = [
    OPENCV,
    TENSORFLOW,
    PYTORCH,
    REACT,
    REDUX,
    NODEJS,
    BOOTSTRAP,
    FLUTTER,
    EXPO,
    ANDROID_STUDIO,
    CLOUD_FIRESTORE,
    REALTIME_DATABASE,
    CLOUD_STORAGE,
    GAZEBO,
    UNITY,
    GRADLE,
    TRAVIS,
    GIT,
    GITHUB,
    ARM_CORTEX,
    ARDUINO,
    RASPBERRY,
    PHOTOSHOP,
    PREMIERE_PRO,
];
