import React from "react";
import { Badge } from "react-bootstrap";
import TopTooltip from "../tooltips/TopTooltip";

export default function ListTitle({ variant, name, website, tooltip, imageSource, imageAlt, time, location }) {
    const COMPANY_LOGO_HEIGHT = "40em";
    const PROJECT_LOGO_HEIGHT = "40em";

    return (
        <dt className={variant}>
            {website ? (
                !imageSource ? (
                    <span>
                        <a href={website} target="_blank" rel="noreferrer noopener">
                            {<TopTooltip message={tooltip}>{name}</TopTooltip>}
                        </a>{" "}
                    </span>
                ) : (
                    <span>
                        {name}
                        <a href={website} target="_blank" rel="noreferrer noopener">
                            <TopTooltip message={tooltip}>
                                <img
                                    src={imageSource}
                                    alt={imageAlt}
                                    height={COMPANY_LOGO_HEIGHT}
                                    width="auto"
                                    style={{ padding: "0.3em" }}
                                />
                            </TopTooltip>
                        </a>
                    </span>
                )
            ) : (
                <span>
                    {name}
                    {imageSource ? (
                        <img
                            src={imageSource}
                            alt={imageAlt}
                            height={PROJECT_LOGO_HEIGHT}
                            width="auto"
                            style={{ padding: "0.3em" }}
                        />
                    ) : null}
                </span>
            )}
            {time ? (
                <Badge pill variant="dark">
                    {time}
                </Badge>
            ) : null}
            {location ? (
                <span>
                    {" | "}
                    <Badge pill variant="primary">
                        {location}
                    </Badge>
                </span>
            ) : null}
        </dt>
    );
}
