import React from "react";
import { useWindowSize } from "react-use";
import { CUT_OFF_MEDIUM_SCREEN_WIDTH } from "../../constants/Constants";
import IconsGrid from "../grid/IconsGrid";
import List from "./List";

export default function TechStack({ stack }) {
    const { width } = useWindowSize();

    const DEFAULT_NUMBER_OF_COLS_LARGE_SCREEN = 6;
    const DEFAULT_NUMBER_OF_COLS_SMALL_SCREEN = 4;

    const NUMBER_OF_COLUMNS =
        width >= CUT_OFF_MEDIUM_SCREEN_WIDTH
            ? DEFAULT_NUMBER_OF_COLS_LARGE_SCREEN
            : DEFAULT_NUMBER_OF_COLS_SMALL_SCREEN;

    return (
        <div>
            <List.Title variant="TechStack" name="Tech Stack" />
            <IconsGrid numOfCols={NUMBER_OF_COLUMNS} lst={stack} />
        </div>
    );
}
