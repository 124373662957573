export const TA_DESCRIPTION_DETAILS = [
    {
        title: "CS1010E Programming Methodology",
        website: "https://nusmods.com/modules/CS1010E/programming-methodology",
        tooltip: "Module Details",
        time: "JAN 2020 - PRESENT",
        description: {
            details: [
                "Conducted tutorial sessions",
                "Introduced Python programming language and fundamental concepts of computational problem solving to students",
                "Graded and gave feedbacks on students’ assignments",
            ],
        },
    },
    {
        title: "CP2106 Independent Software Development Project (Orbital)",
        website: "https://orbital.comp.nus.edu.sg/",
        tooltip: "Module Details",
        time: "MAY 2021 - AUG 2021",
        description: {
            details: [
                "Advised junior students on their software engineering projects",
                "Provided timely feedback and periodic evaluation on the participating projects",
            ],
        },
    },
    {
        title: "CG2271 Real-Time Operating Systems",
        website: "https://nusmods.com/modules/CG2271/real-time-operating-systems",
        tooltip: "Module Details",
        time: "JAN 2021 - MAY 2021",
        description: {
            details: [
                "Conducted programming tutorial sessions for 67 students",
                "Reinforced students’ knowledge on RTOS concepts",
                "Enhanced concurrent real-time programming competencies in students",
            ],
        },
    },
];
