import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useWindowSize } from "react-use";
import SectionWrapper from "../../components/SectionWrapper";
import { CUT_OFF_LARGE_SCREEN_WIDTH } from "../../constants/Constants";
import { PORTFOLIO_ANCHOR } from "../../constants/RoutePath";
import Achievements from "./Achievements";
import CCA from "./CCA";
import Projects from "./Projects";

export default function Portfolio() {
    const { width } = useWindowSize();

    return (
        <SectionWrapper title="Portfolio" id={PORTFOLIO_ANCHOR}>
            <Tabs defaultActiveKey="projects" variant="pills" class="TabButtonContainer">
                <Tab eventKey="projects" title="PROJECTS">
                    <div style={{ animation: "fadeInAnimation ease 1s" }}>
                        <Projects />
                    </div>
                </Tab>
                <Tab eventKey="achievements" title="ACHIEVEMENTS">
                    <div style={{ animation: "fadeInAnimation ease 1s" }}>
                        <Achievements />
                    </div>
                </Tab>
                <Tab eventKey="cca" title={width >= CUT_OFF_LARGE_SCREEN_WIDTH ? "CO-CURRICULAR ACTIVITIES" : "CCA"}>
                    <div style={{ animation: "fadeInAnimation ease 1s" }}>
                        <CCA />
                    </div>
                </Tab>
            </Tabs>
        </SectionWrapper>
    );
}
