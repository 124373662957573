import { faChalkboardTeacher, faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import List from "../../components/list/List";
import { MODULE_DETAILS_LIST, MODULE_TITLES_LIST } from "../../constants/TaTimelineDetails";

export default function TaTimeline() {
    return (
        <div className="TimelineContainer">
            <VerticalTimeline>
                {MODULE_DETAILS_LIST.map(({ moduleTitles, date }, index) => (
                    <VerticalTimelineElement
                        contentStyle={{
                            borderTopWidth: "5px",
                            borderTopStyle: "solid",
                            borderTopColor: index === 0 ? "#e91e63" : "#2ca8ff",
                        }}
                        date={date}
                        iconStyle={{
                            color: "#fff",
                            fontSize: "18px",
                            backgroundColor: index === 0 ? "#e91e63" : "#2ca8ff",
                        }}
                        icon={<FontAwesomeIcon icon={faChalkboardTeacher} />}
                    >
                        <List
                            variant="TimelineTitle"
                            listDetails={MODULE_TITLES_LIST(moduleTitles)}
                            style={{ paddingTop: "0px" }}
                        />
                    </VerticalTimelineElement>
                ))}

                <VerticalTimelineElement
                    iconStyle={{ background: "#10cc52", color: "#fff", fontSize: "20px" }}
                    icon={<FontAwesomeIcon icon={faGraduationCap} />}
                    date="Matriculated in Aug 2019"
                />
            </VerticalTimeline>
        </div>
    );
}
