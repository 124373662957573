import React from "react";
import { Row, Col, Badge } from "react-bootstrap";
import SectionWrapper from "../../components/SectionWrapper";
import { NUS_LOGO, STANFORD_LOGO } from "../../constants/LogoPath";
import { useWindowSize } from "react-use";
import {
    CUT_OFF_MEDIUM_SCREEN_WIDTH,
    CUT_OFF_LARGE_SCREEN_WIDTH,
    CUT_OFF_SMALL_SCREEN_WIDTH,
} from "../../constants/Constants";
import { EDUCATION_ANCHOR } from "../../constants/RoutePath";

export default function Education() {
    const { width } = useWindowSize();

    const IMG_HEIGHT =
        width >= CUT_OFF_LARGE_SCREEN_WIDTH
            ? "100em"
            : width >= CUT_OFF_MEDIUM_SCREEN_WIDTH
            ? "90em"
            : "75em";

    const LOGO_HORIZONTAL_ALIGN = "center";
    const UNI_DESCRIPTION_ALIGN = width >= CUT_OFF_SMALL_SCREEN_WIDTH ? "left" : "center";

    return (
        <div>
            <SectionWrapper title="Education" id={EDUCATION_ANCHOR}>
                <div class="Education">
                    <Row>
                        <Col
                            xl={1}
                            sm={2}
                            style={{
                                display: "flex",
                                justifyContent: LOGO_HORIZONTAL_ALIGN,
                                alignItems: "center",
                                marginBottom: "1em",
                            }}
                        >
                            <img src={NUS_LOGO.source} alt={NUS_LOGO.alt} height={IMG_HEIGHT} />
                        </Col>
                        <Col
                            xl={3}
                            sm={4}
                            style={{ marginBottom: "1em", textAlign: UNI_DESCRIPTION_ALIGN }}
                        >
                            <text>
                                <b>National University of Singapore</b>
                            </text>
                            <br />
                            <Badge pill variant="dark">
                                2019 - Present
                            </Badge>
                            <br />
                            <text>Bachelor of Computer Engineering</text>
                        </Col>
                        <Col
                            xl={1}
                            sm={2}
                            style={{
                                display: "flex",
                                justifyContent: LOGO_HORIZONTAL_ALIGN,
                                alignItems: "center",
                                marginBottom: "1em",
                            }}
                        >
                            <img
                                src={STANFORD_LOGO.source}
                                alt={STANFORD_LOGO.alt}
                                height={IMG_HEIGHT}
                            />
                        </Col>
                        <Col
                            xl={3}
                            sm={4}
                            style={{ marginBottom: "1em", textAlign: UNI_DESCRIPTION_ALIGN }}
                        >
                            <text>
                                <b>Stanford University</b>
                            </text>
                            <br />
                            <Badge pill variant="dark">
                                2021 - 2022
                            </Badge>
                            <br />
                            <text>NUS Overseas College Programme (Silicon Valley)</text>
                        </Col>
                    </Row>
                </div>
            </SectionWrapper>
        </div>
    );
}
