import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import round_ava from "../../assets/images/tienkhoa.png";
import Avatar from "@mui/material/Avatar";

export default function Greetings() {
    return (
        <div className="HeaderBackground" id="home">
            <Container>
                <Row className="HeaderColumns fadeIn">
                    <Col className="AvatarContainer" xl={5}>
                        <Avatar
                            alt="Tien-Khoa"
                            src={round_ava}
                            sx={{ width: "11em", height: "11em" }}
                            className="Avatar bounce border border-solid border-dark"
                        />
                    </Col>
                    <Col>
                        <h1 className="Greetings">Greetings, I am Khoa</h1>
                        <p className="HeaderEdu" id="contact">
                            Computer Engineering, Year 4 <br />
                            National University of Singapore
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
