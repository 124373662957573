import React from "react";
import List from "../../components/list/List";
import SectionWrapper from "../../components/SectionWrapper";
import { INTERNSHIP_DETAILS } from "../../constants/InternshipDetails";

export default function Internship() {
    return (
        <SectionWrapper title="Internship">
            <List listDetails={INTERNSHIP_DETAILS} style={{ borderBottomWidth: "1px" }} />
        </SectionWrapper>
    );
}
