import { BGARAGE } from "./LogoPath";

export const INTERNSHIP_DETAILS = [
    {
        title: "Software Engineering Intern @",
        image: BGARAGE,
        website: "https://www.bgarage.ai/",
        tooltip: "B Garage Website",
        time: "JUL 2021 - JUL 2022",
        location: "San Jose, CA",
        description: {
            details: [
                "Applied computer vision to detect inventory information in warehouses",
                "Designed and implemented a routing algorithm for optimal drone flying path",
                "Developed warehouse simulation environment for autonomous drone testing",
                "Created a multi-platform application to observe the status of drones and charging stations",
                "Integrated software components and enhanced the reliability of data pipeline"
            ]
        },
    },
];
