import ieee_hkn from "../assets/images/cca/ieee-hkn.png";
import orbital_apollo from "../assets/images/cca/orbital_apollo.png";
import shopee_code_league from "../assets/images/cca/shopee_code_league.png";
import BGarageLogo from "../assets/images/company-logo/bgarage.png";
import android_studio from "../assets/images/frameworks/android-studio.png";
import arduino from "../assets/images/frameworks/arduino.png";
import arm_cortex from "../assets/images/frameworks/arm_cortex.png";
import bootstrap from "../assets/images/frameworks/bootstrap.png";
import cloud_firestore from "../assets/images/frameworks/cloud-firestore.png";
import cloud_storage from "../assets/images/frameworks/cloud-storage.png";
import expo from "../assets/images/frameworks/expo.png";
import git from "../assets/images/frameworks/git.png";
import github from "../assets/images/frameworks/github.png";
import gradle from "../assets/images/frameworks/gradle.png";
import nodejs from "../assets/images/frameworks/node-js.png";
import photoshop from "../assets/images/frameworks/photoshop.png";
import premiere_pro from "../assets/images/frameworks/premier-pro.png";
import raspberry_pi from "../assets/images/frameworks/raspberry-pi.png";
import react from "../assets/images/frameworks/react-native.png";
import realtime_database from "../assets/images/frameworks/realtime-database.png";
import redux from "../assets/images/frameworks/redux.png";
import travis from "../assets/images/frameworks/travis.png";
import asm from "../assets/images/languages/asm.png";
import cpp from "../assets/images/languages/c++.png";
import c from "../assets/images/languages/c.png";
import css from "../assets/images/languages/css3.png";
import html from "../assets/images/languages/html5.png";
import java from "../assets/images/languages/java.png";
import js from "../assets/images/languages/javascript.png";
import matlab from "../assets/images/languages/matlab.png";
import sql from "../assets/images/languages/my-sql.png";
import python from "../assets/images/languages/python.png";
import vivado_logo from "../assets/images/languages/vivado_logo.png";
import alex from "../assets/images/project-logo/alex.png";
import fitnus from "../assets/images/project-logo/FitNUS.png";
import mbot from "../assets/images/project-logo/mbot.png";
import rtos_bot from "../assets/images/project-logo/rtos.png";
import schwarzenegger from "../assets/images/project-logo/schwarzenegger.png";
import sound_entertainment from "../assets/images/project-logo/sound_entertaiment.png";
import stanford_logo from "../assets/images/school-logo/stanford_logo.png";
import nus_logo from "../assets/images/school-logo/nus_logo.png";
import c_sharp from "../assets/images/languages/c_sharp.png";
import gazebo from "../assets/images/frameworks/gazebo.png";
import unity from "../assets/images/frameworks/unity.png";
import flutter from "../assets/images/frameworks/flutter.png";
import young_sdg from "../assets/images/cca/young_sdg_logo.png";
import nus_enterprise from "../assets/images/cca/nus_enterprise.png";
import moledao from "../assets/images/cca/moledao.png";
import laser_tag from "../assets/images/project-logo/laser_tag.png";
import weapon_detection from "../assets/images/project-logo/weapon_detection.png";
import pytorch from "../assets/images/frameworks/pytorch.png";
import opencv from '../assets/images/frameworks/opencv.png';
import tensorflow from '../assets/images/frameworks/tensorflow.png';
import cpu from '../assets/images/project-logo/cpu.png';

/* University logo */
export const STANFORD_LOGO = { source: stanford_logo, alt: "Stanford logo" };
export const NUS_LOGO = { source: nus_logo, alt: "NUS logo" };

/* Languages */
export const PYTHON = { source: python, alt: "Python logo", caption: "Python" };
export const C = { source: c, alt: "C logo", caption: "C" };
export const CPP = { source: cpp, alt: "C++ logo", caption: "C++" };
export const C_SHARP = { source: c_sharp, alt: "C# logo", caption: "C#" };
export const JAVA = { source: java, alt: "Java logo", caption: "Java" };
export const JAVA_SCRIPT = { source: js, alt: "JavaScript logo", caption: "JavaScript" };
export const HTML = { source: html, alt: "HTML5 logo", caption: "HTML5" };
export const CSS = { source: css, alt: "CSS3 logo", caption: "CSS3" };
export const SQL = { source: sql, alt: "MySQL logo", caption: "MySQL" };
export const MATLAB = { source: matlab, alt: "MATLAB logo", caption: "MATLAB" };
export const VERILOG = { source: vivado_logo, alt: "Vivado logo", caption: "Verilog" };
export const ASM = { source: asm, alt: "ASM logo", caption: "Assembly" };

/* Frameworks */
export const REACT = { source: react, alt: "React logo", caption: "ReactJS & React Native" };
export const REDUX = { source: redux, alt: "Redux logo", caption: "Redux" };
export const NODEJS = { source: nodejs, alt: "Node.js logo", caption: "Node.js" };
export const EXPO = { source: expo, alt: "Expo logo", caption: "Expo" };
export const ANDROID_STUDIO = {
    source: android_studio,
    alt: "Android Studio logo",
    caption: "Android Studio",
};
export const CLOUD_FIRESTORE = {
    source: cloud_firestore,
    alt: "Cloud Firestore logo",
    caption: "Cloud Firestore | Firebase",
};
export const REALTIME_DATABASE = {
    source: realtime_database,
    alt: "Realtime Database logo",
    caption: "Realtime Database | Firebase",
};
export const CLOUD_STORAGE = {
    source: cloud_storage,
    alt: "Cloud Storage logo",
    caption: "Cloud Storage | Firebase",
};
export const BOOTSTRAP = { source: bootstrap, alt: "Bootstrap logo", caption: "Bootstrap" };
export const GRADLE = { source: gradle, alt: "Gradle logo", caption: "Gradle" };
export const TRAVIS = { source: travis, alt: "Travis CI logo", caption: "Travis CI" };
export const GIT = { source: git, alt: "Git logo", caption: "Git" };
export const GITHUB = { source: github, alt: "GitHub logo", caption: "GitHub" };
export const ARDUINO = { source: arduino, alt: "Adruino logo", caption: "Adruino" };
export const GAZEBO = { source: gazebo, alt: "Gazebo logo", caption: "Gazebo" };
export const UNITY = { source: unity, alt: "Unity logo", caption: "Unity" };
export const FLUTTER = { source: flutter, alt: "Flutter logo", caption: "Flutter" };
export const RASPBERRY = {
    source: raspberry_pi,
    alt: "Raspberry Pi logo",
    caption: "Raspberry Pi",
};
export const PHOTOSHOP = { source: photoshop, alt: "Photoshop logo", caption: "Photoshop" };
export const PREMIERE_PRO = {
    source: premiere_pro,
    alt: "Premiere Pro logo",
    caption: "Premiere Pro",
};
export const ARM_CORTEX = {
    source: arm_cortex,
    alt: "ARM Cortex-M0+ Processor logo",
    caption: "ARM Cortex-M0+ Processor",
};
export const PYTORCH = {
    source: pytorch,
    alt: "PyTorch logo",
    caption: "PyTorch",
}

export const OPENCV = {
    source: opencv,
    alt: "OpenCV logo",
    caption: "OpenCV",
}

export const TENSORFLOW = {
    source: tensorflow,
    alt: "TensorFlow logo",
    caption: "TensorFlow",
}

/* Internships */
export const BGARAGE = { source: BGarageLogo, alt: "BGarage logo" };

/* Projects */
export const WEAPON_DETECTION = { source: weapon_detection, alt: "Weapon Detection logo" };
export const LASER_TAG = { source: laser_tag, alt: "Laser Tag logo" };
export const CPU = { source: cpu, alt: "CPU" };
export const SCHWARZENEGGER = { source: schwarzenegger, alt: "Schwarzenegger logo" };
export const FITNUS = { source: fitnus, alt: "FitNUS logo" };
export const SOUND_ENTERTAINMENT = { source: sound_entertainment, alt: "Mozzie logo" };
export const RTOS_BOT = { source: rtos_bot, alt: "Racing bot logo" };
export const ALEX = { source: alex, alt: "Alex logo" };
export const MBOT = { source: mbot, alt: "MBot logo" };

/* CCA */
export const APOLLO = { source: orbital_apollo, alt: "NUS Orbital Apollo 11 logo" };
export const YOUNG_SDG = { source: young_sdg, alt: "Young SDG Leaders Awards logo" };
export const SHOPEE_CODE_LEAGUE = {
    source: shopee_code_league,
    alt: "Shoppe Code League 2020 logo",
};
export const IEEE_HKN = { source: ieee_hkn, alt: "IEEE HKN logo" };
export const NUS_ENTERPRISE = { source: nus_enterprise, alt: "NUS Enterprise logo" };
export const MOLEDAO = { source: moledao, alt: "MoleDAO logo" };

/* Files */
export const RESUME = `${process.env.PUBLIC_URL + "/Resume_TienKhoa_Nguyen.pdf"}`;
export const SHOPEE_CODE_LEAGUE_CERTIFICATE = `${process.env.PUBLIC_URL + "/Shopee_Code_League_Certificate.pdf"
    }`;
export const YOUNG_SDG_ARTICLE = `${process.env.PUBLIC_URL + "/Young_SDG_Leaders_Article.pdf"}`;
