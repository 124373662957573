import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import List from "../../components/list/List";
import SectionWrapper from "../../components/SectionWrapper";
import TaDescription from "./TaDescription";
import TaTimeline from "./TaTimeline";

export default function TeachingAssistant() {
    return (
        <SectionWrapper title="Teaching Assistant Experience">
            <List.Title name="NUS Undergraduate Teaching Assistant for the Following Modules:" />
            <Tabs defaultActiveKey="timeline" variant="pills" class="TabButtonContainer">
                <Tab eventKey="timeline" title="TIMELINE">
                    <TaTimeline />
                </Tab>
                <Tab eventKey="description" title="DESCRIPTION">
                    <div style={{ animation: "fadeInAnimation ease 1s" }}>
                        <TaDescription />
                    </div>
                </Tab>
            </Tabs>
        </SectionWrapper>
    );
}
