import {
    ALEX,
    ARDUINO,
    ARM_CORTEX,
    ASM,
    C,
    CLOUD_FIRESTORE,
    CLOUD_STORAGE,
    CPP,
    CPU,
    EXPO,
    FITNUS,
    GRADLE,
    JAVA,
    JAVA_SCRIPT,
    LASER_TAG,
    MBOT,
    NODEJS,
    OPENCV,
    PYTHON,
    RASPBERRY,
    REACT,
    REALTIME_DATABASE,
    RTOS_BOT,
    SCHWARZENEGGER,
    SOUND_ENTERTAINMENT,
    TENSORFLOW,
    UNITY,
    VERILOG,
    WEAPON_DETECTION
} from "./LogoPath";

export const PROJECT_DETAILS = [
    {
        title: "Peas - The Threat Detector",
        image: WEAPON_DETECTION,
        description: {
            details: [
                "Developed a robust model to detect weapons in a short video",
                "Applied YOLOv5 to filter invalid videos having no appearance of a person",
                "Developed a multimodal learning approach using Inception v3 on image and audio data",
                "Achieved accuracy of 82.64% on the dataset collected by students in the course"
            ],
        },
        techStack: [TENSORFLOW, OPENCV, PYTHON],
        documentation: [
            { variant: "poster", website: "https://github.com/FizzyAgent/cs4243-weapons-detection/blob/master/project_poster.pdf" },
            { variant: "repo", website: "https://github.com/FizzyAgent/cs4243-weapons-detection" },
            { variant: "video", website: "https://youtu.be/iFPYetaO3Ls" },
        ],
        time: "AUG 2022 - NOV 2022",
    },
    {
        title: "Laser Tag++ - The Laser Tag Shooting Game",
        image: LASER_TAG,
        description: {
            details: [
                "Developed an augmented reality (AR) game to visualize laser tag shooting",
                "Implemented frameworks for player detection and motion tracking",
                "Visualized real-time gameplay on Android and iOS"
            ],
        },
        techStack: [UNITY, PYTHON],
        documentation: [
            { variant: "repo", website: "https://github.com/CG4002-B3/software_visualizer" },
            { variant: "video", website: "https://youtu.be/nKTPNiXV2OA" },
        ],
        time: "AUG 2022 - NOV 2022",
    },
    {
        title: "Arm - The ARM v3 Processor",
        image: CPU,
        description:
        {
            details: [
                "Implemented an FPGA-based processor consisting of Decoder, Register File, ALU and Memory units to execute ARM-based instructions including data processing, memory I/O and branch",
                "Developed a 5-stage pipeline and implemented circuits to resolve data hazards",
                "Integrated efficient branch prediction algorithm to improve clock per instruction"
            ]
        },
        techStack: [VERILOG, ASM],
        time: "OCT 2022 - DEC 2022",
    },
    {
        title: "Schwarzenegger - The Fitness Tracking Application",
        image: SCHWARZENEGGER,
        description: {
            details: [
                "Developed a software to track workout and diet and to suggest fitness plans based on physique data",
                "Implemented algorithms to suggest physique objectives to maximize workout performance",
                "Built and integrated new API with existing components",
                "Managed product releases on GitHub, set up project site and introduced continuous integration to the project",
            ],
        },
        techStack: [JAVA, GRADLE],
        documentation: [
            { variant: "repo", website: "https://github.com/tienkhoa16/tp" },
            { variant: "website", website: "https://tienkhoa16.github.io/tp/" },
        ],
        time: "AUG 2020 - NOV 2020",
    },
    {
        title: "FitNUS - The Temperature Tracking Mobile Application",
        image: FITNUS,
        description: {
            details: [
                "Developed an Android application for NUS students to monitor their health during COVID-19 pandemic",
                "Alerted users to declare temperature twice a day and updated NUS circulars",
                "Implemented real-time in-app messaging to enable users to consult doctors on COVID-19 symptoms",
            ],
        },
        techStack: [REACT, EXPO, JAVA_SCRIPT, NODEJS, CLOUD_FIRESTORE, CLOUD_STORAGE, REALTIME_DATABASE],
        documentation: [
            { variant: "video", website: "https://youtu.be/TbHHFOPMjfQ" },
            { variant: "repo", website: "https://github.com/tienkhoa16/Albitor" },
        ],
        time: "MAY 2020 - AUG 2020",
    },
    {
        title: "Mozzie - The FPGA-Based Voice-Controlled Game",
        image: SOUND_ENTERTAINMENT,
        description: {
            details: [
                "Created a game on FPGA platform to allow the players to use voice to control character’s movements",
                "Implemented a video player on FPGA where users use their voice to adjust the play speed",
                "Developed an arithmetic unit to execute math operations on FPGA",
            ],
        },
        techStack: [VERILOG, PYTHON],
        documentation: [{ variant: "repo", website: "https://github.com/tienkhoa16/fpga-sound-display-project" }],
        time: "MAR 2020 - APR 2020",
    },
    {
        title: "Rav - The Racing Bot",
        image: RTOS_BOT,
        description: {
            details: [
                "Built a robot which can move in multiple directions, play music and blink the LED in various colors",
                "Developed an Android application which allows users to control the robot from their smartphones",
                "Implemented various RTOS concepts to control the scheduling and concurrency of the system",
            ],
        },
        techStack: [ARM_CORTEX, C],
        documentation: [
            { variant: "video", website: "https://youtu.be/feZvdmqvYLk" },
            { variant: "repo", website: "https://github.com/tienkhoa16/cg2271-project" },
        ],
        time: "SEP 2020 - NOV 2020",
    },
    {
        title: "Alex - The Search and Rescue Robot",
        image: ALEX,
        description: {
            details: [
                "Built a search and rescue robot which can navigate in an unseen environment",
                "Supported control of the robot using command lines from computer",
                "Used LiDAR and ROS to map the environment back to the user",
                "Established TLS client-server setup to secure the connection between Alex and computer",
            ],
        },
        techStack: [RASPBERRY, ARDUINO, C, CPP],
        documentation: [
            { variant: "video", website: "https://youtu.be/zdC5WwNk6z0" },
            { variant: "repo", website: "https://github.com/k-walter/CG1112_Alex" },
        ],
        time: "FEB 2020 - APR 2020",
    },
    {
        title: "mBot - The Smart Navigator",
        image: MBOT,
        description: {
            details: [
                "Built an automated robot which can navigate itself through a maze in minimum time",
                "Used IR proximity and ultrasonic sensors to detect the wall and avoid collision",
                "Developed color sensor to decode the challenge at each checkpoint",
            ],
        },
        techStack: [ARDUINO, C],
        documentation: [{ variant: "repo", website: "https://github.com/tienkhoa16/mBot" }],
        time: "OCT 2020 - NOV 2020",
    },
];
