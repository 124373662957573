import React from "react";

export default function ListItem({ description, variant }) {
    const DESCRIPTION_INDEX = 0;
    const HYPERLINK_INDEX = 1;

    switch (variant) {
        case "bullet-point":
            return (
                <dd style={{ margin: "0em" }}>
                    {"\u2022"}{" "}
                    {typeof description === "object" ? (
                        <a
                            href={description[HYPERLINK_INDEX]}
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{ color: "#e64c40", fontWeight: "700" }}
                        >
                            {description[DESCRIPTION_INDEX]}
                        </a>
                    ) : (
                        description
                    )}
                </dd>
            );
        default:
            return (
                <dd>
                    {"\u2022"} {description}.
                </dd>
            );
    }
}
