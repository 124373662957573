import React from "react";

export default function SectionWrapper({ id, title, children }) {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="SectionContainer" id={id}>
                <div class="SectionTopPadding" />
                <div className="SectionTitle">{title}</div>
                <div className="SectionContentContainer">{children}</div>
            </div>
        </div>
    );
}
