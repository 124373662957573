import React from "react";
import TopTooltip from "../tooltips/TopTooltip";

export default function InfoButton({ color, url, message, icon, alt }) {
    const WIDTH = "30em";

    return (
        <TopTooltip message={message}>
            <a href={url} target="_blank" rel="noreferrer noopener">
                <div class="button info-button" style={{ backgroundColor: color }}>
                    <img src={icon} height="auto" width={WIDTH} alt={alt} />
                </div>
            </a>
        </TopTooltip>
    );
}
