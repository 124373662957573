import React from "react";
import ListContainer from "./ListContainer";
import ListDocumentation from "./ListDocumentation";
import ListItem from "./ListItem";
import ListSubtitle from "./ListSubtitle";
import ListTitle from "./ListTitle";
import TechStack from "./TechStack";

export default function List({ variant, listDetails, style }) {
    return (
        <div>
            {listDetails.map(
                (
                    { title, image, website, tooltip, time, location, subtitle, description, techStack, documentation },
                    index
                ) => (
                    <List.Container
                        style={{
                            borderBottomWidth: index === listDetails.length - 1 ? "0px" : "1px",
                            ...style,
                        }}
                    >
                        <List.Title
                            variant={variant ? variant : null}
                            name={title}
                            imageSource={image ? image.source : null}
                            imageAlt={image ? image.alt : null}
                            website={website ? website : null}
                            tooltip={tooltip ? tooltip : null}
                            time={time}
                            location={location}
                        />
                        {subtitle ? <List.Subtitle subtitle={subtitle} /> : null}
                        {description
                            ? description.details.map((detail) => (
                                  <List.Item variant={description.variant} description={detail} />
                              ))
                            : null}
                        {techStack ? <List.TechStack stack={techStack} /> : null}
                        {documentation
                            ? documentation.map(({ variant, website }) => (
                                  <List.Documentation variant={variant} website={website} />
                              ))
                            : null}
                    </List.Container>
                )
            )}
        </div>
    );
}

List.Container = ListContainer;
List.Title = ListTitle;
List.Subtitle = ListSubtitle;
List.Item = ListItem;
List.TechStack = TechStack;
List.Documentation = ListDocumentation;
