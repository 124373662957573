export const MODULE_DETAILS_LIST = [
    {
        moduleTitles: ["CS1010E Programming Methodology"],
        date: "Aug 2021 - Present",
    },
    {
        moduleTitles: ["CP2106 Independent Software Development Project (Orbital)"],
        date: "May 2021 - Aug 2021",
    },
    {
        moduleTitles: ["CG2271 Real-Time Operating Systems", "CS1010E Programming Methodology"],
        date: "Jan 2021 - May 2021",
    },
    {
        moduleTitles: ["CS1010E Programming Methodology"],
        date: "Jan 2020 - Dec 2020",
    },
];

export const MODULE_TITLES_LIST = (moduleTitles) => {
    var titlesList = [];
    moduleTitles.map((title) => titlesList.push({ title: title }));
    return titlesList;
};
