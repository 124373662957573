import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { animateScroll, scroller } from "react-scroll";
import {
    CONTACT_ANCHOR,
    EDUCATION_ANCHOR,
    EXPERIENCES_ANCHOR,
    PORTFOLIO_ANCHOR,
    SKILLS_ANCHOR,
} from "../constants/RoutePath";

export default class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavExpanded: false,
        };

        this.setIsNavExpanded = (isNavExpanded) => {
            this.setState({ isNavExpanded: isNavExpanded });
        };

        this.handleClick = (e) => {
            if (this.node.contains(e.target)) {
                // clicked inside menu
                return;
            }

            // clicked outside menu
            this.setIsNavExpanded(false);
        };
    }

    componentDidMount() {
        document.addEventListener("click", this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClick, false);
    }

    render() {
        return (
            <div ref={(node) => (this.node = node)}>
                <Navbar
                    collapseOnSelect
                    expand="xl"
                    className="NavigationBar"
                    fixed="top"
                    onToggle={this.setIsNavExpanded}
                    expanded={this.state.isNavExpanded}
                >
                    <Container>
                        <Navbar.Brand
                            bsPrefix="HeaderBrand"
                            onClick={() => {
                                this.setIsNavExpanded(false);
                                animateScroll.scrollToTop({
                                    smooth: true,
                                    duration: 500,
                                });
                            }}
                        >
                            Tien-Khoa Nguyen
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav">
                            <span style={{ color: "#e64c40" }}>
                                {this.state.isNavExpanded ? (
                                    <span class="material-icons md-36 rotate">close</span>
                                ) : (
                                    <span class="material-icons md-36 fadeInFast">menu</span>
                                )}
                            </span>
                        </Navbar.Toggle>
                        <Navbar.Collapse>
                            <div id="responsive-navbar-nav">
                                <Nav>
                                    <Nav.Link
                                        bsPrefix="SectionLinks"
                                        onClick={() => {
                                            this.setIsNavExpanded(false);
                                            scroller.scrollTo(`${EDUCATION_ANCHOR}`, {
                                                smooth: true,
                                                duration: 500,
                                            });
                                        }}
                                    >
                                        Education
                                    </Nav.Link>
                                    <Nav.Link
                                        bsPrefix="SectionLinks"
                                        onClick={() => {
                                            this.setIsNavExpanded(false);
                                            scroller.scrollTo(`${SKILLS_ANCHOR}`, {
                                                smooth: true,
                                                duration: 500,
                                            });
                                        }}
                                    >
                                        Skills
                                    </Nav.Link>
                                    <Nav.Link
                                        bsPrefix="SectionLinks"
                                        onClick={() => {
                                            this.setIsNavExpanded(false);
                                            scroller.scrollTo(`${EXPERIENCES_ANCHOR}`, {
                                                smooth: true,
                                                duration: 500,
                                            });
                                        }}
                                    >
                                        Experiences
                                    </Nav.Link>
                                    <Nav.Link
                                        bsPrefix="SectionLinks"
                                        onClick={() => {
                                            this.setIsNavExpanded(false);
                                            scroller.scrollTo(`${PORTFOLIO_ANCHOR}`, {
                                                smooth: true,
                                                duration: 500,
                                            });
                                        }}
                                    >
                                        Portfolio
                                    </Nav.Link>
                                    <Nav.Link
                                        bsPrefix="SectionLinks"
                                        onClick={() => {
                                            this.setIsNavExpanded(false);
                                            scroller.scrollTo(`${CONTACT_ANCHOR}`, {
                                                smooth: true,
                                                duration: 500,
                                            });
                                        }}
                                    >
                                        Contact
                                    </Nav.Link>
                                </Nav>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}
