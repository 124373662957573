import React from "react";
import { useWindowSize } from "react-use";
import { CUT_OFF_LARGE_SCREEN_WIDTH } from "../constants/Constants";

export default function LanguageLogo({ img, alt }) {
    const { width } = useWindowSize();
    const IMG_HEIGHT = width >= CUT_OFF_LARGE_SCREEN_WIDTH ? "60em" : "35em";

    return (
        <div class="LanguageLogo">
            <img src={img} alt={alt} height={IMG_HEIGHT} width="auto" />
        </div>
    );
}
