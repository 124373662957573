import { APOLLO, MOLEDAO, NUS_ENTERPRISE, PYTHON, SHOPEE_CODE_LEAGUE, SHOPEE_CODE_LEAGUE_CERTIFICATE, YOUNG_SDG, YOUNG_SDG_ARTICLE } from "./LogoPath";

export const ACHIEVEMENT_DETAILS = [
    {
        title: "NUS Faculty of Engineering Deans' List",
        subtitle: "Awarded to top 5% of each cohort",
        website: "https://ceg.nus.edu.sg/",
        tooltip: "NUS Computer Engineering Department Website",
        description: {
            variant: "bullet-point",
            details: [
                "AY20/21 Semester 1",
                ["AY20/21 Semester 2", "https://credentials.nus.edu.sg/4e1ce5c0-444e-4688-b218-c5fdfe703e5a"],
            ],
        },
    },
    {
        title: "Young Sustainable Development Goals Leaders Award 2022",
        time: "MAR 2022 - JUL 2022",
        image: YOUNG_SDG,
        website: "https://youngsdgleaders.com/",
        tooltip: "Young SDG Leaders Award Website",
        description: {
            variant: "bullet-point",
            details: [
                ["Awarded First Runner-Up", YOUNG_SDG_ARTICLE],
                "Designed an Internet of Things solution to suggest an eco-friendly commute route.",
                "Developed a smart battery charging scheme to prolong battery life of electric vehicles."
            ],
        },
    },
    {
        title: "NUS Venture Initiation Programme Awards",
        time: "JAN 2022 - JUL 2022",
        image: NUS_ENTERPRISE,
        website: "https://enterprise.nus.edu.sg/funding/nus-venture-initiation-programme/",
        tooltip: "NUS VIP Award Website",
        description: {
            details: [
                "Awarded for pursuing an entrepreneurship idea on developing a platform connecting online content creators with their supporters by using NFTs"
            ],
        },
    },
    {
        title: "Molehack - NFT 2.0",
        time: "MAR 2022",
        image: MOLEDAO,
        website: "https://www.moledao.io/#/home",
        tooltip: "MoleDAO Website",
        description: {
            details: [
                "Awarded Early Mole Awards for promising NFT projects",
                "Developed a platform to connect online content creators with their supporters using NFTs"
            ],
        },
        documentation: [
            {
                variant: "video",
                website: "https://youtu.be/8tgHg6fYiyg",
            }
        ]
    },
    {
        title: "Orbital 2020 (Apollo 11 - Advanced)",
        image: APOLLO,
        website: "https://orbital.comp.nus.edu.sg/",
        tooltip: "NUS Orbital Programme Website",
        description: {
            details: [
                "Participated in NUS School of Computing’s summer independent software development course where first year students work in pairs to complete a software project of their choice in an iterative software development life cycle",
                "Achieved positive feedback from mentors and peers",
            ],
        },
        time: "MAY 2020 - AUG 2020",
        documentation: [
            {
                variant: "certificate",
                website: "https://credentials.nus.edu.sg/539b39e6-32e8-4277-b3f4-63b48aaa3c10",
            },
        ],
    },
    {
        title: "Shopee Code League 2020",
        image: SHOPEE_CODE_LEAGUE,
        website: "https://careers.shopee.sg/codeleague/",
        tooltip: "Shopee Code League Website",
        description: {
            details: [
                "Participated in a 2-month online coding challenge consisting of a series of coding competitions",
                "Attended online training workshops open to all students and professionals across the region",
            ],
        },
        time: "JUN 2020 - AUG 2020",
        techStack: [PYTHON],
        documentation: [
            {
                variant: "certificate",
                website: SHOPEE_CODE_LEAGUE_CERTIFICATE,
            },
        ],
    }
];
