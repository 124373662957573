import React from "react";
import InfoButton from "../../components/button/InfoButton";
import { CONTACT_DETAILS } from "../../constants/ContactDetails";

export default function Contact() {
    return (
        <div class="ContactContainer">
            {CONTACT_DETAILS.map(({ color, icon, url, message, alt }) => (
                <InfoButton color={color} icon={icon} url={url} message={message} alt={alt} />
            ))}
        </div>
    );
}
