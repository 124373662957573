import React from "react";
import NavigationBar from "./components/NavigationBar";
import Contact from "./sections/contact/Contact";
import Education from "./sections/education/Education";
import Experiences from "./sections/experiences/Experiences";
import Greetings from "./sections/greetings/Greetings";
import Portfolio from "./sections/portfolio/Portfolio";
import Resume from "./sections/resume/Resume";
import Skills from "./sections/skills/Skills";
import "./styles.css";
function App() {
    return (
        <div className="App">
            <NavigationBar />
            <Greetings />
            <Contact />
            <Resume />
            <Education />
            <Skills />
            <Experiences />
            <Portfolio />
        </div>
    );
}

export default App;
