import React from "react";
import List from "./List";

export default function ListDocumentation({ variant, name, website, tooltip }) {
    switch (variant) {
        case "repo":
            return (
                <div>
                    <List.Title
                        variant="Documentation"
                        name="Project Repository"
                        website={website}
                        tooltip="View Source Code on GitHub"
                    />
                </div>
            );
        case "website":
            return (
                <div>
                    <List.Title
                        variant="Documentation"
                        name="Website"
                        website={website}
                        tooltip="View Project Website"
                    />
                </div>
            );
        case "video":
            return (
                <div>
                    <List.Title
                        variant="Documentation"
                        name="Demo Video"
                        website={website}
                        tooltip="Watch Project Demo"
                    />
                </div>
            );
        case "certificate":
            return (
                <div>
                    <List.Title
                        variant="Documentation"
                        name="Certificate"
                        website={website}
                        tooltip="View Certificate"
                    />
                </div>
            );
        case "poster":
            return (
                <div>
                    <List.Title
                        variant="Documentation"
                        name="Project Poster"
                        website={website}
                        tooltip="View Project Poster"
                    />
                </div>
            );
        default:
            return (
                <div>
                    <List.Title variant="Documentation" name={name} website={website} tooltip={tooltip} />
                </div>
            );
    }
}
