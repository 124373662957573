import github from "../assets/images/frameworks/github_white_background.svg";
import gmail from "../assets/images/frameworks/gmail.svg";
import linkedin from "../assets/images/frameworks/linkedin.svg";

export const CONTACT_DETAILS = [
    {
        color: "black",
        icon: github,
        url: "https://github.com/tienkhoa16",
        message: "Follow me on GitHub",
        alt: "GitHub icon",
    },
    {
        color: "#0a66c2",
        icon: linkedin,
        url: "https://www.linkedin.com/in/ntkhoa/",
        message: "Connect with me on LinkedIn",
        alt: "LinkedIn icon",
    },
    {
        color: "#ea4335",
        icon: gmail,
        url: "mailto:tienkhoa@u.nus.edu",
        message: "Email me",
        alt: "Email icon",
    },
];
