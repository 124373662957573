import React from "react";
import { RESUME } from "../../constants/LogoPath";

export default function Resume() {
    return (
        <div class="ResumeContainer">
            <a class="button ResumeButton" href={RESUME} target="_blank" rel="noreferrer noopener">
                Resume
            </a>
        </div>
    );
}
