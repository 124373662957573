import React from "react";
import List from "./List";

export default function ListSubtitle({ subtitle }) {
    return (
        <div>
            <List.Title variant="ListSubtitle" name={subtitle} />
        </div>
    );
}
