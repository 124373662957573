export const SKILLS_PATH = "/skills";
export const EXPERIENCES_PATH = "/experiences";
export const PORTFOLIO_PATH = "/portfolio";
export const DEFAULT_PATH = "/";
export const CONTACT_ANCHOR = "contact";
export const HOME_ANCHOR = "#";
export const SKILLS_ANCHOR = "skills";
export const EDUCATION_ANCHOR = "education";
export const EXPERIENCES_ANCHOR = "experiences";
export const PORTFOLIO_ANCHOR = "portfolio";
