import React from "react";
import { EXPERIENCES_ANCHOR } from "../../constants/RoutePath";
import Internship from "./Internship";
import TeachingAssistant from "./TeachingAssistant";

export default function Experiences() {
    return (
        <div id={EXPERIENCES_ANCHOR}>
            <Internship />
            <TeachingAssistant />
        </div>
    );
}
